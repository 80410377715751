@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./fonts/Futura.ttf) format('truetype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 900;
  src: local('Futura'), url(./fonts/Futura-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'),
    url(./fonts/HelveticaNeueLt.ttf) format('truetype');
}
